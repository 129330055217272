import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { ArrayElement } from '@lib/types/arrayElement'
import { type TrialAwardBadgeLabel } from '@modules/trials/etl/ingestTrial2024/handleTrialAwardBadges/types'
import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'

import { getFontColorClassName } from './getFontColorClassName'
import { getIcon } from './getIcon'

export const AwardBadgePill = ({
  label,
  name,
  tags,
}: Pick<
  ArrayElement<TrialInAlgolia['awardBadges']>,
  'label' | 'name' | 'tags'
>) => {
  const fontColorClassName = getFontColorClassName(tags)
  const icon = getIcon(label as TrialAwardBadgeLabel, tags)

  return (
    <div className='rounded-[8px] border border-neutral300 bg-neutral50 px-4 py-1'>
      <Icon
        className={classMerge('mr-2 inline-block', fontColorClassName)}
        icon={icon}
      />
      <Text
        className='inline text-neutral600'
        styleName='p-small'
        value={name}
      />
    </div>
  )
}
