'use client'

import useIsMobileScreen from '@components/hooks/useIsMobileScreen'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import Link from '@components/primitives/Link'
import { faMicroscope } from '@fortawesome/pro-regular-svg-icons/faMicroscope'
import type { Url } from '@lib/routes'
import { type TrackingSources } from '@lib/tracking/TrackingSources'
import saveUserSearch from '@modules/userSearchInstances/saveUserSearch'
import type { UserSearchParams } from '@pages/api/v1/search'
import { trackViewDetailsClick } from './trackViewDetailsClick'

export const NavigateToTrialWrapper = ({
  children,
  className,
  fireSearchEvent,
  linkToTrial,
  trackingSource,
  userSearchParams,
}: {
  children: JSX.Element
  className?: string
  fireSearchEvent: boolean
  linkToTrial: Url
  trackingSource: TrackingSources
  userSearchParams?: UserSearchParams
}) => {
  const isMobileScreen = useIsMobileScreen()
  return (
    <div
      className={className}
      onClick={() => {
        trackViewDetailsClick(
          linkToTrial.toString(),
          trackingSource,
          userSearchParams,
        )
        // Trigger a `listingView` event so we can mark the current search instance as completed
        if (fireSearchEvent) {
          saveUserSearch({ type: 'listingView' })
        }
      }}
    >
      <Link href={linkToTrial} newTab={!isMobileScreen}>
        {children}
      </Link>
    </div>
  )
}

export const LearnMoreButton = ({
  linkToTrial,
  trackingSource,
}: {
  linkToTrial: Url
  trackingSource: TrackingSources
}) => (
  <Button
    className='w-full rounded-full'
    color='black'
    onClick={() =>
      trackViewDetailsClick(linkToTrial.toString(), trackingSource)
    }
  >
    <span className='font-bold text-white'>
      <Icon className='mr-3 text-white' icon={faMicroscope} />
      Learn More
    </span>
  </Button>
)
