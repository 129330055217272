'use client'

import { trackEventWithFacebook } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { type TrackingSources } from '@lib/tracking/TrackingSources'
import type { UserSearchParams } from '@pages/api/v1/search'

export const trackViewDetailsClick = (
  linkToTrial: string,
  trackingSource: TrackingSources,
  userSearchParams?: UserSearchParams,
) => {
  trackEventWithFacebook(TrackingEvents.TRIAL_LISTING_CLICKED, {
    path: linkToTrial,
    source: trackingSource,
    val: JSON.stringify(userSearchParams),
  })
}
